import React, { useEffect, useState, useMemo } from "react";
import TextField from "@mui/material/TextField";
import { triggerBase64Download } from "common-base64-downloader-react";
import {
  Container,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
} from "reactstrap";
import TableContainer from "../componentsCFDI/TableContainer";
import "../componentsCFDI/estilo.css";
import "../componentsCFDI/table.css";

import "../componentsCFDI/modal.css";
import Spinner from "../componentsCFDI/S.gif";
import Base64Downloader from "react-base64-downloader";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import {
  SelectColumnFilter,
  ColumnFilter,
  ColumnFilter2,
  SelectColumnFilter2,
} from "../componentsCFDI/filters";
import axios from "axios";
import * as tokenn from "../tokens/token";
import * as acesstokenn from "../tokens/token";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import CfdiTableMotivo from "../componentsCFDI/Motivo01";
import { get } from "lodash";
import LoadingScreen from "react-loading-screen";
import { StickyTable, Row, Cell } from "react-sticky-table";
import tache from "../componentsCFDI/borrar.png";
import tacheCANCEL from "../componentsCFDI/cancelado.png";
import PDF from "../componentsCFDI/pdf.png";
import PDFCANCEL from "../componentsCFDI/pdfcancel.png";
import XML from "../componentsCFDI/xml.png";
import XMLCANCEL from "../componentsCFDI/xmlcancel.png";
import EMAIL from "../componentsCFDI/correoElectronico.png";
import ConfirmModal from "../componentsCFDI/ModalConfirm";
import ReenvioCorreo from "../componentsCFDI/reenviocorreo";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ConsultarFacturasProvedores = () => {
  const [isModal, setModal] = React.useState(false);
  const [mensajeDescarga, setmensajeDescarga] = useState("");

  const currencies = [
    { value: "01", label: "Comprobante emitido con errores con relación" },
    { value: "02", label: "Comprobante emitido con errores sin relación" },
    { value: "03", label: "No se llevó a cabo la operación" },
    {
      value: "04",
      label: "Operación nominativa relacionada en una factura global",
    },
  ];

  const dialog = useDialog();

  const [data, setData] = useState([]);
  const [ff, setff] = useState("");
  const [fi, setfi] = useState("");
  const [rfc, setRFC] = useState("");
  const [sdn, setSDN] = useState("");
  const [cancel, setCancel] = useState(null);
  const [tipodoc, setTipoDoc] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [documentType, setdocumentType] = useState("");

  const [descargapdf, setdescargapdf] = useState("");
  const [base64, setbase64] = useState("");

  const [uuidCorreo, setuuidCorreo] = React.useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [currency, setCurrency] = React.useState("");

  const [isLoding, setIsLoding] = useState(true);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const handleChangee = (event) => {
    setCurrency(event.target.value);
  };

  const buttonHandler = () => {
    if (fi <= ff) {
      setIsLoading((current) => !current);
    } else {
      alert("La fecha inicial debe ser menor que la final");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoding(true);

    const uri =
      "https://h93zxd4wrb.execute-api.us-east-1.amazonaws.com/od/supplier-invoices";

    axios
      .get(uri, {
        params: {
          di: fi || "",
          df: ff || "",
        },
        headers: {
          Authorization: sessionStorage.getItem("idToken"),
          "access-token": sessionStorage.getItem("accessToken"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data;

        setData(data);
        setTimeout(() => {
          setIsLoding(false);
          setff("");
          setfi("");
          setRFC("");
          setSDN("");
        }, 1000);
      })
      .catch((err) => {
        console.log(err);

        if (err == "Error: Network Error") {
          console.log("No autorizado");
        }

        setData([]);

        console.log("----------------------------");
        console.log("HOLA AQUI VA OTRA COSA");
        console.log("AXIOS ERROR: ", err);
        console.log("----------------------------");

        setTimeout(() => {
          setIsLoding(false);
        }, 1000);
      });
  };

  useEffect(() => {
    const getFacture = () => {


      var date = new Date();

      var dia = `${date.getDate()}`.padStart(2, "0");
      var mes = `${date.getMonth() + 1}`.padStart(2, "0");
      var año = date.getFullYear();

      var mesantes = Number(mes) - 1;

      if (mesantes == 0) {
        mesantes = `${12}`.padStart(2, "0");
      } else {
        mesantes = `${mesantes}`.padStart(2, "0");
      }

      const recuperarPPD = `${año}-${mes}-${dia}`;
      const recuperarPPDantes = `${año}-${mesantes}-${dia}`;

      if (data.length < 1) {
       
        const uri =
          "https://h93zxd4wrb.execute-api.us-east-1.amazonaws.com/od/supplier-invoices";

        axios
          .get(uri, {
            params: {
              di: ""||recuperarPPDantes,
              df: ""||recuperarPPD,
            },
            headers: {
              Authorization: sessionStorage.getItem("idToken"),
              "access-token": sessionStorage.getItem("accessToken"),
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            const data = response.data;
            setData(data);
            setIsLoding(false);
          })
          .catch((err) => {
            console.log("error", err);
            setIsLoding(false);

          });
      }

    };
    getFacture();

  }, [isLoading]);

  const columns = useMemo(
    () => [
      {
        Header: "Acciones",
        id: "Acciones",

        Cell: ({ valor, row }) => {
          function eliminarFactura() {
            setOpen(true);

            const uri = `https://h93zxd4wrb.execute-api.us-east-1.amazonaws.com/od/supplier-invoices/${row.original.id}`;

            axios
              .delete(uri, {
                headers: {
                  Authorization: sessionStorage.getItem("idToken"),
                  "access-token": sessionStorage.getItem("accessToken"),
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                const data = response.data;
                window.location="/consultaFacturasProvedores";
                setOpen(false);
              })
              .catch((err) => {
                console.log("error", err);
                setOpen(false);
                setIsLoding(false);

              });
          }

          function downloadPDF(typedocumentexmlorpdf) {
            setOpen(true);

            const uri = `https://h93zxd4wrb.execute-api.us-east-1.amazonaws.com/od/supplier-invoices/${row.original.id}`;

            axios
              .get(uri, {
                params: {
                  filename: row.original.filename,
                  documentType: typedocumentexmlorpdf,
                },
                headers: {
                  Authorization: sessionStorage.getItem("idToken"),
                  "access-token": sessionStorage.getItem("accessToken"),
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                const data = response.data;

                if (typedocumentexmlorpdf == "xml") {
                  var blob = base64toBlobxml(data.file);

                  const blobUrl = URL.createObjectURL(blob);
                  const downloadLink = document.createElement("a");
                  const fileName = row.original.folio + ".xml";
                  downloadLink.href = blobUrl;
                  let arr = row.original.filename.split("/");
                  console.log(arr[4] + ".xml");

                  downloadLink.download = arr[4] + ".xml";
                  downloadLink.click();
                  setTimeout(() => {
                    setmensajeDescarga("");

                    setOpen(false);
                  }, 2000);
                } else {
                  var blob = base64toBlob(data.file);
                  const blobUrl = URL.createObjectURL(blob);
                  const downloadLink = document.createElement("a");
                  const fileName = row.original.folio + ".pdf";
                  downloadLink.href = blobUrl;
                  let arr = row.original.filename.split("/");

                  downloadLink.download = arr[4] + ".pdf";
                  downloadLink.click();
                  setTimeout(() => {
                    setmensajeDescarga("");

                    setOpen(false);
                  }, 2000);
                }
              })
              .catch((err) => {
                console.log("error", err);
                setOpen(false);
              });
          }

          function base64toBlob(base64Data) {
            const sliceSize = 1024;
            const byteCharacters = atob(base64Data);
            const bytesLength = byteCharacters.length;
            const slicesCount = Math.ceil(bytesLength / sliceSize);
            const byteArrays = new Array(slicesCount);

            for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
              const begin = sliceIndex * sliceSize;
              const end = Math.min(begin + sliceSize, bytesLength);

              const bytes = new Array(end - begin);
              for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
              }
              byteArrays[sliceIndex] = new Uint8Array(bytes);
            }
            return new Blob(byteArrays, { type: "application/pdf" });
          }

          function base64toBlobxml(base64Data) {
            const sliceSize = 1024;
            const byteCharacters = atob(base64Data);
            const bytesLength = byteCharacters.length;
            const slicesCount = Math.ceil(bytesLength / sliceSize);
            const byteArrays = new Array(slicesCount);

            for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
              const begin = sliceIndex * sliceSize;
              const end = Math.min(begin + sliceSize, bytesLength);

              const bytes = new Array(end - begin);
              for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
              }
              byteArrays[sliceIndex] = new Uint8Array(bytes);
            }
            return new Blob(byteArrays, { type: "application/xml" });
          }

          if (row.original.cancel == false) {
            return (
              <div>
                <div className="img-cancel">
                  <img
                    src={tache}
                    className="centerText, cancel"
                    onClick={async () => {
                      const result = await Confirm(
                        "¿Esta usted seguro de eliminar esta factura?",
                        "Eliminar factura de proveedor",
                        "Si",
                        "No"
                      );

                      if (result) {
                        eliminarFactura("xml");
                      } else {
                        // Сonfirmation not confirmed
                      }
                    }}
                  ></img>
                  <img
                    src={XML}
                    onClick={() => downloadPDF("xml")}
                    className="centerText, cancel"
                  ></img>
                  <img
                    src={PDF}
                    onClick={() => downloadPDF("pdf")}
                    className="centerText, cancel"
                  ></img>
                </div>
              </div>
            );
          }
        },
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Fecha",
        accessor: "invoice_date",
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Serie",
        accessor: "serie",
        id: "seriee",
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        id: "cancel",
        Header: "Folio",
        accessor: "folio",
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 1000,
        Header: "Cliente",
        accessor: "name_business_partner",
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,

        Header: "RFC",
        accessor: "rfc_business_partner",
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,

        Header: "Subtotal",
        accessor: (d) => {
          return d.subtotal
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.subtotal)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.subtotal);
        },
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,

        Header: "Descuento",
        accessor: (d) => {
          return d.discount
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.discount)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.discount);
        },
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,

        Header: "Impuesto",
        accessor: (d) => {
          return d.tax
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.tax)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.tax);
        },
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,

        Header: "Total",
        accessor: (d) => {
          return d.total
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total);
        },
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,

        Header: "Método de pago",
        accessor: "payment_method",
        id: "payment",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,

        Header: "Tipo de comprobante",
        accessor: "tipodecomprobante",
        Filter: SelectColumnFilter,
        filter: "equals",
      },

      {
        Filter: SelectColumnFilter,
        filter: "equals",
        Header: "Estado",
        accessor: (d) => {
          return d.cancel == true ? "CANCELADO" : "VIGENTE";
        },
      },

      {
        Header: "Usuario",
        accessor: "username",
      },
    ],
    []
  );

  return (
    <div className="TableConsultarTodo">
      <div>
        <Modal
          open={open}
          onClose={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="centermodaldescarga">
              <Typography
                id="modal-modal-title"
                className=""
                variant="h6"
                component="h2"
              >
                <img src={Spinner}></img>
              </Typography>
              <Typography
                id="modal-modal-description"
                className=""
                sx={{ mt: 2 }}
              >
                Descargando factura...
              </Typography>
            </div>
          </Box>
        </Modal>
      </div>

      <div className="">
        {isLoding ? (
          <LoadingScreen
            className="LoadinScreen"
            loading={true}
            bgColor="#f1f1f1"
            spinnerColor="#9ee5f8"
            textColor="#676767"
            logoSrc={Spinner}
            text={mensajeDescarga || "Buscando..."}
            value={true}
          />
        ) : (
          <div className="">
            <StickyTable>
              <div className="div1c">
                <input
                  type="text"
                  className="fi03"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha inicial"
                  onChange={(event) => setfi(event.target.value)}
                ></input>

                <input
                  type="text"
                  className="fi03"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha Final"
                  onChange={(event) => setff(event.target.value)}
                ></input>
              </div>
              <div className="div2c"></div>

              <div className="buscarfacturas">
                <button className="button" onClick={handleSubmit} type="button">
                  Buscar
                </button>

                <button
                  className="button filtrosBusqueda"
                  onClick={async () => {
                    const result = await CustomDialog(
                      <div className="gidModal">
                        <div className="mp1">
                          <select
                            id="canceladas-si-no"
                            className="filtroCancel"
                            onChange={(event) => setCancel(event.target.value)}
                          >
                            <option>Estado del documento</option>
                            <option value={true}>CANCELADO</option>
                            <option value={false}>VIGENTE</option>
                            <option value={""}>TODOS</option>
                          </select>
                        </div>
                        <div className="mp2">
                          <select
                            className="filtroCancel"
                            onChange={(event) => setTipoDoc(event.target.value)}
                          >
                            <option>Tipo de documento</option>
                            <option value={"FACTURA"}>FACTURA</option>
                            <option value={"NOTA DE CREDITO"}>
                              NOTA DE CREDITO
                            </option>
                            <option value={"TRASLADO"}>CARTA PORTE</option>
                            <option value={"NOMINA"}>CARTA PORTE</option>
                            <option value={"PAGO"}>PAGO</option>
                            <option value={"null"}>TODOS</option>
                          </select>

                          <input
                            type="text"
                            placeholder="Buscar por RFC"
                            onChange={(event) => setRFC(event.target.value)}
                          ></input>
                        </div>
                        <div className="mp3">
                          <input
                            type="text"
                            placeholder="Buscar por socio de negocios"
                            onChange={(event) => setSDN(event.target.value)}
                          ></input>
                        </div>
                        <div className="mp4"></div>
                      </div>,
                      {
                        className: "modalTItle",
                        title: "Filtros de Busqueda",
                        showCloseIcon: true,
                      }
                    );
                  }}
                >
                  {`Filtros de Busqueda`}
                </button>
              </div>

              <TableContainer
                columns={columns}
                data={data}
                style={{ overflowx: 10 }}
              />
            </StickyTable>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConsultarFacturasProvedores;
