import { applyMiddleware, createStore, StoreEnhancer } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'
import { rootReducer } from "./reducers";
// import monitorReducersEnhancer from './enhancers/monitorReducers'
// import loggerMiddleware from './middleware/logger'


export default function configureStore() {
//   const middlewares = [loggerMiddleware, thunkMiddleware]
  const middlewares = [thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

//   const enhancers = [middlewareEnhancer, monitorReducersEnhancer]
  const enhancers = [middlewareEnhancer]
  const composedEnhancers = composeWithDevTools(...enhancers) as StoreEnhancer;

//   const store = createStore(rootReducer, preloadedState, composedEnhancers)
  const store = createStore(rootReducer, composedEnhancers);

  return store
}