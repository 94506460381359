import { createContext, useContext, useState } from "react";

const SidebarContext = createContext<{
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}>({
    isOpen: false,
    setIsOpen: () => null
});

const SidebarProvider: React.FC = ({ children }) => {

    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <SidebarContext.Provider value={{isOpen, setIsOpen}}>
            {children}
        </SidebarContext.Provider>
    );
};

const useSidebar = () => {
    const contextValue = useContext(SidebarContext);
    if (contextValue === undefined) {
        throw new Error('useSidebar must be used within a SidebarProvider');
    }
    return contextValue
};

export { SidebarProvider, useSidebar };