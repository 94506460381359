import React, { Component } from "react";
import { AuthState } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";
import BG from "../img/user.png";
import LG from "../img/logo.png";
import axios from "axios";
import { get_telegram_secrets } from "../services/supports/supports";


export class ConfirmSignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Code: "",
      Username: "",
    };

    this.confirmSignUp = this.confirmSignUp.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
  }

  async resendConfirmationCode() {
    try {
      await Auth.resendSignUp(this.state.Username);
      // console.log('code resent successfully');
    } catch (err) {
      // console.log('error resending code: ', err);
    }
  }

  handleFormSubmission(evt) {
    evt.preventDefault();
    this.confirmSignUp();
  }

  async confirmSignUp() {
    const username = this.state.Username;
    const code = this.state.Code;

    try {
      await Auth.confirmSignUp(username, code);

      //notificacion a telegram
      var r = await get_telegram_secrets();

      console.log(r?.data?.token);
      console.log(r?.data?.grupo);

      const apiUrl = `https://api.telegram.org/bot${r?.data?.token}/sendMessage`;
      var mensaje = 
      "AGENTE SMIT-SEGUIMIENTO\n\n\n"+
      "Nuevo Proveedor Registrado:\n\n"+
      "Nombre: *"+ localStorage.getItem("name_supplier")+"*\n\n"+
      "Correo: *"+ localStorage.getItem("email_supplier")+"*";
      const params = {
        chat_id: r?.data?.grupo,
        text: mensaje,
        parse_mode: 'Markdown',
      };
      axios.post(apiUrl, params)
        .then((response) => {
          console.log('Mensaje enviado:', response.data);
        })
        .catch((error) => {
          console.error('Error al enviar el mensaje:', error);
        });


      this.props.SetAuthState(AuthState.SignIn);
    } catch (error) {
      // console.log('error confirming sign up', error);
    }
  }

  render() {
    return (
      <div className="colorfondo">
        <div className="parent clearfix">
          <div className="bg-illustration">
          </div>

          <div className="login">
            <div className="container">
              <br></br>
              <h2 className="titleOD2">Verifica tu correo</h2>
                      <div className="form-group">
                        <input className="form-control text-center" type="text" placeholder="Username" onChange={(e) => this.setState({Username: e.target.value})} ></input>

                      </div>

                      <div className="form-group">
                        <input className="text-center form-control" type="text" placeholder="Ingresa codigo de verificación" onChange={(e) => this.setState({Code: e.target.value})}></input>
                      </div>
                      
                      <div className="form-group">
                        <a className="form-control btn btn-primary" onClick={this.handleFormSubmission} >Verificar Cuenta</a>
                      </div>
                      <div className="form-group">
                        {/* <a className="form-control btn btn-primary" onClick={() => this.resendConfirmationCode} href="#0">Reenviar Codigo</a> */}
                      </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmSignUp;

{
  /* <div className="imgfondo">
          <div className='ftco-section'>
              <div className="container ">
               
                <div className="row justify-content-center">
                <div className="col-md-6 col-lg-4">
                  <div className="login-wrap p-0">
                    <h3 className="mb-4 text-center">Verifica tu correo</h3>
                      <div className="form-group">
                        <input className="form-control text-center" type="text" placeholder="Username" onChange={(e) => this.setState({Username: e.target.value})} ></input>

                      </div>

                      <div className="form-group">
                        <input className="text-center form-control" type="text" placeholder="Ingresa codigo de verificación" onChange={(e) => this.setState({Code: e.target.value})}></input>
                      </div>
                      
                      <div className="form-group">
                        <a className="form-control btn btn-primary" onClick={this.handleFormSubmission} href="#0">Verificar Cuenta</a>
                      </div>
                      <div className="form-group">
                        <a className="form-control btn btn-primary" onClick={() => this.resendConfirmationCode} href="#0">Reenviar Codigo</a>

                      </div>

                     
                  </div>
                </div>
              </div>
                
         
              </div>
            </div>
        </div> */
}
