
import { SignUp } from 'aws-amplify-react/lib/Auth';
import * as React from 'react';
import { AuthState } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import './login.css'
import validator from 'validator'
import Alert from '@mui/material/Alert';
import BG from "../img/user.png";
import LG from "../img/logo.png";

import MuiAlert from "@material-ui/lab/Alert";
import { get_telegram_secrets } from '../services/supports/supports';


export class CustomSignUp extends SignUp {
  constructor(props) {
    super(props)
  
    this.state = {
      Username : '',
      Password : '', 
      RePassword: '',
      Mail: '',
      Name : '',
      RFC:'',
      ID_COMPANY:'',
      ID_USER:'',
      ROLES:''
    }

    this.signUp = this.signUp.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
  }

  Alert(props) {
    return <MuiAlert elevation={6} 
                     variant="filled" {...props} />;
  }
  handleFormSubmission(evt) {
    if (validator.isLowercase(this.state.Username)) {

      if (validator.isStrongPassword(this.state.Password, {
        minLength: 8, minLowercase: 1,
        minUppercase: 1, minNumbers: 1, minSymbols: 1
      })) {
    
        if(this.state.Password === this.state.RePassword && this.state.Password !== ''){
          evt.preventDefault();
          this.signUp();
        } else{
          alert("Las contraseñas no son iguales");
        }
    
      } else {
        alert('La contraseña debe contener al menos 8 caracteres, una minúscula, una mayúscula, un número y un símbolo especial.');
      }
    }else {
      alert('El nombre del usuario no puede contener mayusculas');
    }

  }


  mostrarContrasena(){
    var tipo = document.getElementById("password-field");
    if(tipo.type == "password"){
        tipo.type = "text";
    }else{
        tipo.type = "password";
    }
  }
  
  mostrarContrasena2(){
    var tipo = document.getElementById("password-field2");
    if(tipo.type == "password"){
        tipo.type = "text";
    }else{
        tipo.type = "password";
    }
  }

  async signUp() {

    const username = this.state.Username;
    const password = this.state.Password;
    const email = this.state.Mail;
    const rfc = this.state.RFC;
    const name = this.state.Name;


    try {
      var dato = { "email": email, "rfc_company":"FCI9908032FA","username":username,"name":name} ;
      axios
      .post(
        "https://blntcbmh9g.execute-api.us-east-1.amazonaws.com/prod/identity",
        dato,
      )
      .then((response) => {
        // console.log("RESPONSE RECEIVED: ", res);
        console.log(response.data.id_company);
        console.log(response.data.id_user);
        console.log(response.data.roles);

        const id_company=response.data.id_company;
        const id_user=response.data.id_user;
        const roles=response.data.roles;


        localStorage.setItem("email_supplier", this.state.Mail);
        localStorage.setItem("name_supplier", this.state.Name)

        const { user } =  Auth.signUp({
          username, password, 
          attributes: {
            email,
            name,
            'custom:rfc_company':`FCI9908032FA`,
            'custom:id_user':`${id_user}`,
            'custom:roles':"ROLE_SUPPLIER",
            'custom:id_company':`${id_company}`,
          }
        });
        this.props.SetAuthState(AuthState.ConfirmSignUp)
      })
      
      .catch(function (error) {
        console.log(error);

      });
      this.props.SetAuthState(AuthState.ConfirmSignUp)
      
    } catch (err) {
      if (err.code === "UsernameExistsException") {   
        alert("Usted no está autorizado para registrarse");

      
      }else if(err.code === ""){   
      }
       else {
        alert("Usted no está autorizado para registrarse");

        this.setState({ error: "An error has occurred." });
        console.error(err);
        this.props.SetAuthState(AuthState.ConfirmSignUp)
      }
    }
  }
  
  showComponent(theme) {
    return (

      <div className="colorfondo">
      <div className="parent clearfix">
        <div className="bg-illustration">
        </div>

        <div className="login">
          <div className="container">
            <br></br>
            <h2 className="titleOD2">Registrarse </h2>
                       <div className="form-group">
                         <input className="form-control text-center txtmin " type="text"   placeholder="Nombre de usuario" onChange={(e) => this.setState({Username: e.target.value})}></input>

                       </div>

                       <div className="form-group">
                         <input className="text-center form-control" type="text" placeholder="Nombre completo" onChange={(e) => this.setState({Name: e.target.value})}></input>
                       </div>
                      

                       <div className="form-group">
                         <input className="text-center form-control" type="text" placeholder="Correo electronico" onChange={(e) => this.setState({Mail: e.target.value})}></input>
                       </div>

                       <div className="form-group">
                         <input id="password-field" className="text-center form-control" type="password" placeholder="Contraseña" onChange={(e) => this.setState({Password: e.target.value})}></input>
                         <span  className="material-symbols-outlined  field-icon " onClick={this.mostrarContrasena}>visibility</span>
                       </div>

                       <div className="form-group">
                       <input id="password-field2" className="text-center form-control" type="password" placeholder="Confirmar contraseña" onChange={(e) => this.setState({RePassword: e.target.value})}></input>
                       <span  className="material-symbols-outlined  field-icon " onClick={this.mostrarContrasena2}>visibility</span>

                       </div>
                       <div className="form-group">
                         <a className="form-control btn btn-primary" onClick={this.handleFormSubmission}>Registrarse</a>
                       </div>
          </div>
        </div>
      </div>
    </div>
      
     
    )
  }
}

export default CustomSignUp






// <div className="imgfondo">
//           <div className='ftco-sectionUp'>
//               <div className="container ">
               
//                 <div class="row justify-content-center">
//                 <div class="col-md-6 col-lg-4">

//                   <div class="login-wrap p-0">
//                     <h3 class="mb-4 text-center">Registrarse </h3>
//                       <div class="form-group">
//                         <input className="form-control text-center txtmin " type="text"   placeholder="Nombre de usuario" onChange={(e) => this.setState({Username: e.target.value})}></input>

//                       </div>

//                       <div class="form-group">
//                         <input className="text-center form-control" type="text" placeholder="Nombre completo" onChange={(e) => this.setState({Name: e.target.value})}></input>
//                       </div>
                      
//                       <div class="form-group">
//                         <input className="text-center form-control" type="text" placeholder="RFC de la empresa" onChange={(e) => this.setState({RFC: e.target.value})}></input>

//                       </div>

//                       <div class="form-group">
//                         <input className="text-center form-control" type="text" placeholder="Correo electronico" onChange={(e) => this.setState({Mail: e.target.value})}></input>
//                       </div>

//                       <div class="form-group">
//                         <input id="password-field" className="text-center form-control" type="password" placeholder="Contraseña" onChange={(e) => this.setState({Password: e.target.value})}></input>
//                         <span  class="material-symbols-outlined  field-icon " onClick={this.mostrarContrasena}>visibility</span>
//                       </div>

//                       <div class="form-group">
//                       <input id="password-field2" className="text-center form-control" type="password" placeholder="Confirmar contraseña" onChange={(e) => this.setState({RePassword: e.target.value})}></input>
//                       <span  class="material-symbols-outlined  field-icon " onClick={this.mostrarContrasena2}>visibility</span>

//                       </div>
//                       <div class="form-group">
//                         <a className="form-control btn btn-primary" onClick={this.handleFormSubmission} href="#0">Registrarse</a>
//                       </div>
//                   </div>
//                 </div>
//               </div>
                
            
//               </div>
//             </div>
//         </div>