import React, {useState, useRef} from 'react';
import {Container, Card, CardContent, makeStyles, Grid, TextField, Button} from '@material-ui/core';
import QrReader from 'react-qr-reader';
import axios from 'axios';
import { Auth } from "aws-amplify";
import index from '../Content/index';
import Spinner from "./S.gif";
import LoadingScreen from "react-loading-screen";

// import Content from './Components/Content'

import * as tokenn from '../tokens/token';
import * as acesstokenn from '../tokens/token';
import SetSocioNegocioBase from './SetSocioNegocio';


// let jwt = Auth.currentAuthenticatedUser().CognitoUser.getSignInUserSession();
// .getAccessToken()
// .getJwtToken();

export const socioNegocioNuevo = SetEscaner;
// console.log(socioNegocioNuevo);

function SetEscaner()   { 
  

  // const [text, setText] = useState('');
  // const [imageUrl, setImageUrl] = useState('');
  const [scanResultFile, setScanResultFile] = useState('Sin resultados');
  const [retornar, setRetornar] = useState('');
  const retornar2=setRetornar;
  const [scanResultWebCam, setScanResultWebCam] =  useState('Sin resultados');
  const classes = useStyles();
  const qrRef = useRef(null);
  const [isLoding, setIsLoding] = useState(false);



  // const generateQrCode = async () => {
  //   try {
  //         const response = await QRCode.toDataURL(text);
  //         setImageUrl(response);
  //   }catch (error) {
  //     console.log(error);
  //   }
  // }
  const handleErrorFile = (error) => {
    // console.log(error);
  }
  const handleScanFile = (result) => {
      if (result) {

          const acceso= String(tokenn.tokenn);
          const accesotoken = String(acesstokenn.acesstokenn);
         
          var data = JSON.stringify({
            "url": result
          });
          setIsLoding(true);
          var config = {
            method: 'put',
            url: 'https://lhtfgusul2.execute-api.us-east-1.amazonaws.com/dev/businesspartners',
            headers: { 
              'Authorization':  acceso, 
              'access-token': accesotoken,
              'Content-Type': 'application/json'
            },
            data : data
          };

          axios(config)
          .then(function (response) {
            setScanResultFile(JSON.stringify(response.data));
            setIsLoding(false);
          })
          .catch(function (error) {
            sessionStorage.setItem("business_partner",error.response.data.business_partner.business_partner);
            sessionStorage.setItem("capital_regime",error.response.data.business_partner.capital_regime);
            sessionStorage.setItem("email",error.response.data.business_partner.email);
            sessionStorage.setItem("first_last_name",error.response.data.business_partner.first_last_name);
            sessionStorage.setItem("name",error.response.data.business_partner.name);
            sessionStorage.setItem("rfc",error.response.data.business_partner.rfc);
            sessionStorage.setItem("second_last_name",error.response.data.business_partner.second_last_name);
            sessionStorage.setItem("tax_regime",error.response.data.business_partner.tax_regime);
            sessionStorage.setItem("city",error.response.data.address.city);
            sessionStorage.setItem("inside_number",error.response.data.address.inside_number);
            sessionStorage.setItem("neighborhood",error.response.data.address.neighborhood);
            sessionStorage.setItem("outdoor_number",error.response.data.address.outdoor_number);
            sessionStorage.setItem("state",error.response.data.address.state);
            sessionStorage.setItem("street",error.response.data.address.street);
            sessionStorage.setItem("zip_code",error.response.data.address.zip_code);
          try{
            setScanResultFile("No se pudo encontar el socio de negocios: ");
            window.location= '/redireccion';
            setIsLoding(false);

          }catch(error){
          
            alert('Escanear denuevo')
          }
           
          });
      }
          
      
  }
  const onScanFile = () => {
    qrRef.current.openImageDialog();
  }
  const handleErrorWebCam = (error) => {
    console.log(error);
  }
  const handleScanWebCam = (result) => {
    if (result){

        var url={url:result};
        const acceso= String(tokenn.tokenn);
          const accesotoken = String(acesstokenn.acesstokenn);
          setIsLoding(true);

          var data = JSON.stringify({
            "url": result
          });

          var config = {
            method: 'put',
            url: 'https://lhtfgusul2.execute-api.us-east-1.amazonaws.com/dev/businesspartners',
            headers: { 
              'Authorization':  acceso, 
              'access-token': accesotoken,
              'Content-Type': 'application/json'
            },
            data : data
          };

          axios(config)
          .then(function (response) {
            setScanResultWebCam(JSON.stringify(response.data));
            setIsLoding(false);


          })
        .catch(function (error) {
          sessionStorage.setItem("business_partner",error.response.data.business_partner.business_partner);
          sessionStorage.setItem("capital_regime",error.response.data.business_partner.capital_regime);
          sessionStorage.setItem("email",error.response.data.business_partner.email);
          sessionStorage.setItem("first_last_name",error.response.data.business_partner.first_last_name);
          sessionStorage.setItem("name",error.response.data.business_partner.name);
          sessionStorage.setItem("rfc",error.response.data.business_partner.rfc);
          sessionStorage.setItem("second_last_name",error.response.data.business_partner.second_last_name);
          sessionStorage.setItem("tax_regime",error.response.data.business_partner.tax_regime);
          sessionStorage.setItem("city",error.response.data.address.city);
          sessionStorage.setItem("inside_number",error.response.data.address.inside_number);
          sessionStorage.setItem("neighborhood",error.response.data.address.neighborhood);
          sessionStorage.setItem("outdoor_number",error.response.data.address.outdoor_number);
          sessionStorage.setItem("state",error.response.data.address.state);
          sessionStorage.setItem("street",error.response.data.address.street);
          sessionStorage.setItem("zip_code",error.response.data.address.zip_code);
        try{
          setScanResultWebCam("No se pudo encontar el socio de negocios: ");
          window.location= '/redireccion';
          setIsLoding(false);

        }catch(error){
        
          alert('Escanear denuevo')
        }
         
        });
    }
   }
  return (

    <div>
     {isLoding ? (
          <LoadingScreen
            className="LoadinScreen"
            loading={true}
            bgColor="#f1f1f1"
            spinnerColor="#9ee5f8"
            textColor="#676767"
            logoSrc={Spinner}
            text={"Escanendo codigo..."}
          />
        ) : (
          <div>


    <Container className={classes.conatiner}>
          <Card>
               <h2 className={classes.title}>Escanear codigo QR, por medio de camara o imagen </h2> 
              <CardContent>
                 <Grid container  justifyContent="space-around" spacing={4}>
                      {/* <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                          <TextField label="Enter Text Here" onChange={(e) => setText(e.target.value)}/>
                          <Button className={classes.btn} variant="contained" 
                            color="primary" onClick={() => generateQrCode()}>Generar QR</Button>
                            <br/>
                            <br/>
                            <br/>
                            {imageUrl ? (
                              <a href={imageUrl} download>
                                  <img src={imageUrl} alt="img"/>
                              </a>) : null}
                      </Grid> */}
                      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <Button className={classes.btn} variant="contained" onClick={onScanFile}>Selecciona el QR a Escanear</Button>
                        <QrReader
                          ref={qrRef}
                          delay={300}
                          style={{width: '100%'}}
                          onError={handleErrorFile}
                          onScan={handleScanFile}
                          legacyMode
                        />
                        <h3>Código escaneado: {scanResultFile}</h3>
                      </Grid>
                      <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                         <h3>Escaneo por  cámara</h3>
                         <QrReader
                         delay={500}
                         style={{width: '100%'}}
                         onError={handleErrorWebCam}
                         onScan={handleScanWebCam}
                         />
                         <h3>Código escaneado: {scanResultWebCam}</h3>
                      </Grid>
                  </Grid>
              </CardContent>
          </Card>
    </Container>
    </div>

        )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
    conatiner: {
      marginTop: 40
      
      
    },
    title: {
      display: 'flex',
      alignItems:  'center',
      background: '#3f51b5',
      color: '#fff',
      padding: 20,
      textAlign:'center'
    },
    btn : {
      marginTop: 10,
      marginBottom: 20
    }
}));
export default SetEscaner;




