import axios from "axios";

export const get_telegram_secrets = async () => {
  const url = `https://kyxq3rrh02.execute-api.us-east-1.amazonaws.com/od/secret_telegram`;
  try {
    const response = await axios.get(url, {
      headers: {
        // Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        // "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};