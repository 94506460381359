import { IBonusTicket, ITicket } from "../../../interfaces";
import { TicketActionTypes } from "./actionTypes";

type TicketType = ITicket | IBonusTicket;

export interface TicketsState {
    tickets: TicketType[],
    isLoading: boolean,
}

const initialState: TicketsState = {
    tickets: [],
    isLoading: false
};

export default function ticketSlice (state = initialState, action: any) {
    switch (action.type) {
        case TicketActionTypes.SET_TICKETS:
            return {
                ...state,
                tickets: action.payload,
                isLoading: false
            }
        case TicketActionTypes.LOADING_TICKETS:
            return {
                ...state,
                isLoading: true
            }
        default:
            return state
    }
}