import React, { useState,useEffect, Suspense} from 'react'
import axios from 'axios';
import './setSocioNegocio.css'
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import * as tokenn from '../tokens/token';
import * as acesstokenn from '../tokens/token';
import * as refresh from '../tokens/token'
import Checkbox from '@mui/material/Checkbox';
import LoadingSpinner from "./Loading";

import { LocalCafe } from '@material-ui/icons';

const ValidationTextField = styled(TextField)({'& input:valid + fieldset': {borderColor: 'green',borderWidth: 2,},'& input:valid:focus + fieldset': {borderLeftWidth: 6,padding: '4px !important',},});
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const currencies = [{value: 'FÍSICA',label: 'FÍSICA',},{value: 'MORAL',label: 'MORAL',},];
const op1 = [{value: 'CLIENTE',label: 'CLIENTE',},{value: 'PROVEEDOR',label: 'PROVEEDOR',},{value: 'AMBOS',label: 'AMBOS',},];
const op2 = [{value: 'PROPIO',label: 'PROPIO',},{value: 'UNILEVER',label: 'UNILEVER',},{value: 'EXTERNO',label: 'EXTERNO',},];
const op3 = [{value: 'G01',label: 'Adquisición de mercancias',},{value: 'G02',label: 'Devoluciones, descuentos o bonificaciones',},
{value: 'G03',label: 'Gastos en general',},
{value: 'I01',label: 'Construcciones',},{value: 'I02',label: 'Mobilario y equipo de oficina por inversiones',},
{value: 'I03',label: 'Equipo de transporte',},{value: 'I04',label: 'Equipo de computo y accesorios',},
{value: 'I05',label: 'Dados, troqueles, moldes, matrices y herramental',},{value: 'I06',label: 'Comunicaciones telefónicas',},
{value: 'I07',label: 'Comunicaciones satelitales',},{value: 'I08',label: 'Otra maquinaria y equipo',},
{value: 'D01',label: 'Honorarios médicos, dentales y gastos hospitalarios',},{value: 'D02',label: 'Gastos médicos por incapacidad o discapacidad',},
{value: 'D03',label: 'Gastos funerales',},{value: 'D04',label: 'Donativos',},{value: 'D05',label: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)',},
{value: 'D06',label: 'Aportaciones voluntarias al SAR',},{value: 'D07',label: 'Primas por seguros de gastos médicos',},
{value: 'D08',label: 'Gastos de transportación escolar obligatoria',},{value: 'D089',label: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones',},
{value: 'D10',label: 'Pagos por servicios educativos (colegiaturas)',},{value: 'P01',label: 'Por definir',},];
const op4 = [{value: '01',label: 'Efectivo',},{value: '02',label: 'Cheque',},{value: '03',label: 'Transferencia Electrénica de fondos',},
{value: '04',label: 'Tarjeta de crédito',},{value: '28',label: 'Tarjeta de débito',},{value: '29',label: 'Tarjeta de servicio',},
{value: '99',label: 'Otros',},{value: 'NA',label: 'No Aplica',},];
const op5 = [{value: '01',label: 'PUE',},{value: '02',label: 'PPD',},];
const op6 = [{value: 'AGUASCALIENTES',label: 'AGUASCALIENTES',},{value: 'BAJA CALIFORNIA',label: 'BAJA CALIFORNIA',},
{value: 'BAJA CALIFORNIA SUR',label: 'BAJA CALIFORNIA SUR',},{value: 'CAMPECHE',label: 'CAMPECHE',},
{value: 'CHIAPAS',label: 'CHIAPAS',},{value: 'CHIHUAHUA',label: 'CHIHUAHUA',},
{value: 'CIUDAD DE MEXICO',label: 'CIUDAD DE MEXICO',},{value: 'COAHUILA',label: 'COAHUILA',},
{value: 'DURANGO',label: 'DURANGO',},{value: 'ESTADO DE MEXICO',label: 'ESTADO DE MEXICO',},
{value: 'GUANAJUATO',label: 'GUANAJUATO',},{value: 'GUERRERO',label: 'GUERRERO',},
{value: 'HIDALGO',label: 'HIDALGO',},{value: 'JALISCO',label: 'JALISCO',},
{value: 'MICHOACAN',label: 'MICHOACAN',},{value: 'MORELOS',label: 'MORELOS',},
{value: 'NAYARIT',label: 'NAYARIT',},{value: 'NUEVO LEON',label: 'NUEVO LEON',},
{value: 'OAXACA',label: 'OAXACA',},{value: 'PUEBLA',label: 'PUEBLA',},
{value: 'QUERETARO',label: 'QUERETARO',},{value: 'SAN LUIS POTOSI',label: 'SAN LUIS POTOSI',},
{value: 'SINALOA',label: 'SINALOA',},{value: 'SONORA',label: 'SONORA',},
{value: 'TABASCO',label: 'TABASCO',},{value: 'TAMAULIPAS',label: 'TAMAULIPAS',},
{value: 'TLAXCALA',label: 'TLAXCALA',},{value: 'VERACRUZ',label: 'VERACRUZ',},
{value: 'YUCATAN',label: 'YUCATAN',},{value: 'ZACATECAS',label: 'ZACATECAS',},
{value: 'OTRO',label: 'OTRO',},];
const op7 = [{value: 'FISCAL',label: 'FISCAL',}];
const op8 = [{value: 'MATRIZ',label: 'MATRIZ',},{value: 'SUCURSAL',label: 'SUCURSAL',},];


const op9 = [
  
  {value: '601',label: 'General de Ley Personas Morales',},
  {value: '603',label: 'Personas Morales con Fines no Lucrativos',},
  {value: '605',label: 'Sueldos y Salarios e Ingresos Asimilados a Salarios',},
  {value: '606',label: 'Arrendamiento',},
  {value: '607',label: 'Régimen de Enajenación o Adquisición de Bienes',},
  {value: '608',label: 'Demás ingresos',},
  {value: '609',label: 'Consolidación',},
  {value: '610',label: 'Residentes en el Extranjero sin Establecimiento Permanente en México',},
  {value: '611',label: 'Ingresos por Dividendos (socios y accionistas)',},
  {value: '612',label: 'Personas Físicas con Actividades Empresariales y Profesionales',},
  {value: '614',label: 'Ingresos por intereses',},
  {value: '615',label: 'Régimen de los ingresos por obtención de premios',},
  {value: '616',label: 'Sin obligaciones fiscales',},
  {value: '620',label: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos',},
  {value: '621',label: 'Incorporación Fiscal',},
  {value: '622',label: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',},
  {value: '623',label: 'Opcional para Grupos de Sociedades',},
  {value: '624',label: 'Coordinados',},
  {value: '625',label: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',},
  {value: '626',label: 'Régimen Simplificado de Confianza',},
  {value: '628',label: 'Hidrocarburos',},
  {value: '629',label: 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales',},
  {value: '630',label: 'Enajenación de acciones en bolsa de valores',},];
  
function SetSocioNegocioBase  () { 

    const [values, setValues] = useState({code: '', business_partner: '', rfc:'',tax_regime:'', name: '',first_last_name: '',second_last_name: '',
                                        balance: '', credit_days: '', business_partner_type: '',capital_regime:'', partner_type: '', person_type: '',
                                        cfdi_use: '', email: '', phone: '', metodo_pago: '', paymethod: '', account_number: '',
                                        allow_invoicing:Boolean, street: '', outdoor_number: '', inside_number: '', neighborhood:'',
                                        zip_code: '', city: '', state: '', town: '', country: '', reference: '', address_type: '',
                                        subsidiary: '',tax_residence:'',num_reg_id_trib:''});
    const [sent, setSent] = useState(false);
    const handleChange = e => {
        const {name, value} = (e.target)
        setValues({...values, [name]: value})
    }

    const [currency, setCurrency] = React.useState('FÍSICA');
    const [opp1, setOP1] = React.useState('CLIENTE');
    const [opp2, setOP2] = React.useState('PROPIO');
    const [opp3, setOP3] = React.useState('G01'); 
    const [opp4, setOP4] = React.useState('01');
    const [opp5, setOP5] = React.useState('01');
    const [opp6, setOP6] = React.useState('AGUASCALIENTES');
    const [opp7, setOP7] = React.useState('FISCAL');
    const [opp8, setOP8] = React.useState('MATRIZ');
    const [opp9, setOP9] = React.useState('');
    const [isLoading, setIsLoading] = useState(false);


    const [list, setList] = useState('');
    
    useEffect(() => {
        
        const acceso= String(tokenn.tokenn);
       

        const accesotoken = String(acesstokenn.acesstokenn);
        axios.get('https://lhtfgusul2.execute-api.us-east-1.amazonaws.com/dev/businesspartners/code', { headers: { 
            'Authorization':  acceso, 
            'access-token': accesotoken,
            'Content-Type': 'application/json'
        } })
        .then((response) => {
          setList(response.data);
        })
        .catch((error) => {
          // console.log(error);
        });
    }, 
    [setList]);


    const handleChangee = (event) => {setCurrency(event.target.value);};
    const handleChangee1 = (event) => {setOP1(event.target.value);};
    const handleChangee2 = (event) => {setOP2(event.target.value);}
    const handleChangee3 = (event) => {setOP3(event.target.value);}
    const handleChangee4 = (event) => {setOP4(event.target.value);}
    const handleChangee5 = (event) => {setOP5(event.target.value);}
    const handleChangee6 = (event) => {setOP6(event.target.value);}
    const handleChangee7 = (event) => {setOP7(event.target.value);}
    const handleChangee8 = (event) => {setOP8(event.target.value);}
    const handleChangee9 = (event) => {setOP9(event.target.value);}
    // const handleChangeecheckbox = (event) => {
    //     var miCheckbox = document.getElementById('miElementoCheckbox');
    //     var forzar= miCheckbox.checked;
    //     console.log(forzar);
    //     return forzar;   
    // }

    // const [oppcode, setCode] = React.useState('');

    // const handleChangeeCode =(event) => {
    //     const forzar= handleChangeecheckbox();

    //     if(forzar==false){
    //         var nuevocodigo=list.code;
    //         return nuevocodigo
    //     }else{
    //         var nuevocodigo= values.code;
    //         return nuevocodigo
    //     }
    // }
    // const nuevocodigo=handleChangeeCode();


    async function handleSubmit(event) {

        event.preventDefault();
        const acceso= String(tokenn.tokenn);
        const accesotoken = String(acesstokenn.acesstokenn);
        

        var data={
            "business_partner": {

                "business_partner": `${values.business_partner}`,
        
                "rfc":`${values.rfc}`,
        
                "tax_regime": `${opp9}`, // "Regimen Fiscal"
        
                "business_partner_type": "CLIENTE", // Lista de opciones
        
                "partner_type": "PROIO", // Lista de opciones
        
                "person_type": (values?.rfc?.length)>12?"FISICA":"MORAL", //depende del rfc
        
                "cfdi_use": `${opp3}`, //Lista de Opciones
        
                "tax_residence": `${values.tax_residence}`,//extranjeros
        
                "num_reg_id_trib":`${values.num_reg_id_trib}`,//extranjeros
        
                "metodo_pago": `${opp5}`, //Lista de opciones
        
                "paymethod": `${opp4}`, //Lista de opciones,
        
                "capital_regime": `${values.capital_regime}`, // "Regimen Capital" 
        
                "code": `${list.code}`,
        
                "force_code": false,// Forzar código
        
                "name": `${values.name}`,
        
                "first_last_name": `${values.first_last_name}`,
        
                "second_last_name": `${values.second_last_name}`,
        
                "email": `${values.email}`,
        
                "phone": `${values.phone}`,
        
                "balance": 0,
        
                "credit_days": 0,
        
                "account_number": `${values.account_number}`,
        
            },
        
            "address": {
      
                "zip_code": `${values.zip_code}`,
      
                "state": `${opp6}`,
          
                "address_type": "FISCAL",
          
                "country": `${values.country}`,   
          
                "neighborhood": `${values.neighborhood}`,
          
                "city": `${values.city}`,
          
                "town": `${values.town}`,
          
                "street":`${values.street}`,
          
                "outdoor_number": `${values.outdoor_number}`,
          
                "inside_number": `${values.inside_number}`,
          
                "reference": `${values.reference}`,
          
                "subsidiary": "MATRIZ",
          
              } 
      
        }

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  acceso,
                'access-token': accesotoken,
            }
          };
          
          axios.post('https://lhtfgusul2.execute-api.us-east-1.amazonaws.com/dev/businesspartners', data, axiosConfig)
          .then((res) => {
            // console.log("RESPONSE RECEIVED: ", res);
            setInterval(alert('Exito se creo el socio de negocios'),60000);
            setInterval(setIsLoading(false), 60000);
          })

          .catch((err) => {
            // console.log("AXIOS ERROR: ", err);
            setInterval(setIsLoading(false), 60000);
            setInterval(alert('No se pudo crear el socio de negocios verifique sus datos'),60000);

          })
    }

        return (
            
           
            <div>
            <br></br>
            {isLoading ? <LoadingSpinner /> : <h1></h1>}            
            <form onSubmit={handleSubmit} disabled={isLoading} >

                 <Box>
                 <h2>DATOS DEL CLIENTE NUEVO</h2>

                    
                    <ValidationTextField
                        label="Codigo"
                        required
                        placeholder="Codigo"
                        sx={{ m: 2, width: '16ch' }}
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        name='code'
                        value={list.code}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                          }}
                    />
                    {/* <Checkbox {...label}  color="success" id='miElementoCheckbox' onChange={handleChangeecheckbox} /> */}
                    <ValidationTextField
                        label="Socio de Negocios "
                        required
                        placeholder="Socio de Negocios"
                        sx={{ m: 2, width: '40ch' }}
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        name='business_partner'
                        value={values.business_partner}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                          }}
                    />
                
                    <ValidationTextField
                        label="RFC"
                        required
                        placeholder="RFC"
                        sx={{ m: 2, width: '20h' }}
                        variant="outlined"
                        defaultValue="Success"
                        id="getRFC"
                        name='rfc'
                        value={values.rfc}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                          }}
                    />
                     <TextField
                    id="regimenfiscal"
                    select
                    label="Regimen fiscal"
                    value={opp9}
                    sx={{ m: 1.5, width: '28ch' }}
                    onChange={handleChangee9}
                    size="small"
                    name='regimenfiscal'
                    InputLabelProps={{
                        shrink: true,
                      }}
                    SelectProps={{
                        native: true,
                    }}
                    >
                    {op9.map((option) => (
                        <option key={option.value} value={option.value}>
                        {option.label}
                        </option>
                    ))}
                    </TextField>
                    <ValidationTextField
                        label="Regimen Capital"
                        
                        placeholder="Regimen Capital"
                        variant="outlined"
                        sx={{ m: 2, width: '28.5ch' }}
                        defaultValue="Success"
                        id="validation-outlined-input"
                        name='capital_regime'
                        value={values.capital_regime}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                          }}
                    />
                    <TextField
                    id="personType"
                    select
                    label="Tipo de Persona"
                    value={currency}
                    sx={{ m: 1.5, width: '12.5ch' }}
                    onChange={handleChangee}
                    name="personType"
                    size="small"
                    InputLabelProps={{
                        shrink: true,
                      }}
                    SelectProps={{
                        native: true,
                    }}
                    >
                    {currencies.map((option) => (
                        <option key={option.value} value={option.value}>
                        {option.label}
                        </option>
                    ))}
                    </TextField>
                    </Box>

                    <br></br>
                    <Box>
                    <h2>DATOS DEL CLIENTE (SOLO SI ES UNA PERSONA FISICA)</h2>

                    <ValidationTextField
                        label="Nombre del representante legal "
                        
                        variant="outlined"
                        placeholder="Nombre del representante legal"                    
                        sx={{ m: 2, width: '54ch' }}
                        defaultValue="Success"
                        id="validation-outlined-input"
                        name='name'
                        value={values.name}
                        InputLabelProps={{
                            shrink: true,
                          }}
                        onChange={handleChange}
                    />

                    <ValidationTextField
                        label="Apellido Paterno del representante legal "
                        
                        placeholder="Apellido Paterno del representante legal"                    
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '54ch' }}
                        name='first_last_name'
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.first_last_name}
                        onChange={handleChange}
                    />
                    <ValidationTextField
                        label="Apellido Materno del representante legal"
                        
                        placeholder="Apellido Materno del representante legal"                    
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '54ch' }}
                        name='second_last_name'
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.second_last_name}
                        onChange={handleChange}
                    />
                    </Box>

                    <br></br>

                    <Box>
                    <h2>DATOS NECESARIOS PARA FACTURAR</h2>

                    <ValidationTextField
                        label="Correo Electronico"
                        required
                        placeholder="Correo Electronico"                    
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '40ch' }}
                        name='email'
                        type="email"
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.email}
                        onChange={handleChange}
                    />
                   
                    
                   

                    <TextField
                    id="CFDIUse"
                    select
                    label="Uso de CFDI"
                    value={opp3}
                    sx={{ m: 1.5, width: '69ch' }}
                    onChange={handleChangee3}
                    size="small"
                    name='CFDIUse'
                    InputLabelProps={{
                        shrink: true,
                      }}
                    SelectProps={{
                        native: true,
                    }}
                    >
                    {op3.map((option) => (
                        <option key={option.value} value={option.value}>
                        {option.label}
                        </option>
                    ))}
                    </TextField>
                    <TextField
                    id="payMethod"
                    select
                    label="Metodo de pago"
                    value={opp4}
                    sx={{ m: 1.5, width: '34ch' }}
                    onChange={handleChangee4}
                    size="small"
                    name='payMethod'
                    InputLabelProps={{
                        shrink: true,
                      }}
                    SelectProps={{
                        native: true,
                    }}
                    >
                    {op4.map((option) => (
                        <option key={option.value} value={option.value}>
                        {option.label}
                        </option>
                    ))}
                    </TextField>

                    <TextField
                    id="metodoPago"
                    select
                    label="Pago"
                    value={opp5}
                    sx={{ m: 1.5, width: '18ch' }}
                    onChange={handleChangee5}
                    size="small"
                    name='metodoPago'
                    InputLabelProps={{
                        shrink: true,
                      }}
                    SelectProps={{
                        native: true,
                    }}
                    >
                    {op5.map((option) => (
                        <option key={option.value} value={option.value}>
                        {option.label}
                        </option>
                    ))}
                    </TextField>

                   

                    </Box>
                    <br></br>

                    <Box>
                    
                    
                    <ValidationTextField
                        label="Calle"
                        
                        placeholder="Calle"                    
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '42ch' }}
                        name='street'
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.street}
                        onChange={handleChange}
                    />

                    <ValidationTextField
                        label="Numero extrerior"
                        placeholder="Numero extrerior"                    
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '15ch' }}
                        name='outdoor_number'
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.outdoor_number}
                        onChange={handleChange}
                    />
                    <ValidationTextField
                        label="Numero interior"
                        
                        placeholder="Numero interior"                    
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '15ch' }}
                        name='inside_number'
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.inside_number}
                        onChange={handleChange}
                    />

                  
                     <ValidationTextField
                        label="Codigo Postal"
                        required
                        placeholder="Codigo Postal"                    
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '15ch' }}
                        name='zip_code'
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.zip_code}
                        onChange={handleChange}
                    />
                     <ValidationTextField
                        label="Municipio"
                        
                        placeholder="Municipio"                    
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '37ch' }}
                        name='city'
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.city}
                        onChange={handleChange}
                    />
                     <TextField
                    id="state"
                    select
                    label="Estado"
                    value={opp6}
                    sx={{ m: 1.5, width: '28ch' }}
                    onChange={handleChangee6}
                    size="small"
                    name='state'
                    InputLabelProps={{
                        shrink: true,
                      }}
                    SelectProps={{
                        native: true,
                    }}
                    >
                    {op6.map((option) => (
                        <option key={option.value} value={option.value}>
                        {option.label}
                        </option>
                    ))}
                    </TextField>
                    </Box>
                    <br></br>

                    <Box>

                     <ValidationTextField
                        label="Referencia"
                        
                        placeholder="Referencia"                    
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '135ch' }}
                        name='reference'
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.reference}
                        onChange={handleChange}
                    />
    

                 </Box>                    
                 <br></br>
             

                 <Box>                
                 <h2>DATOS (SOLO SI ES UN CLIENTE EXTRANJERO)</h2>


                 <ValidationTextField
                        label="País "
                        
                        placeholder="País"
                        variant="outlined"
                        sx={{ m: 2, width: '57ch' }}
                        defaultValue="Success"
                        id="validation-outlined-input"
                        name='tax_residence'
                        value={values.tax_residence}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                          }}
                    />

                    <ValidationTextField
                        label="Número de registro de identificación fiscal "
                        
                        placeholder="Número de registro de identificación fiscal "
                        variant="outlined"
                        sx={{ m: 2, width: '57ch' }}
                        defaultValue="Success"
                        id="validation-outlined-input"
                        name='num_reg_id_trib'
                        value={values.num_reg_id_trib}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                          }}
                    />
                </Box>  
                <br></br>

                 <Button variant="contained" type="submit">Enviar</Button>

                    
                </form>



            </div>
        );
   
}        

export default SetSocioNegocioBase;
