import React from 'react';
import * as ReactDOM from 'react-dom';import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import  {SidebarProvider}  from './contextDashboard/SidebarContext';
import {Provider}  from 'react-redux';
import configureStore from './redux/configureStore';

import './index.css';
import Amplify from 'aws-amplify'
import aws_config from './aws-exports';
const store = configureStore();

Amplify.configure(aws_config)

ReactDOM.render(
      <Provider store={store}>
        <SidebarProvider>
        <App />
      </SidebarProvider>
    </Provider>,
  document.getElementById('root')
);







