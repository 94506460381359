import React, { useState } from 'react'
import axios from 'axios';
import './setSocioNegocio.css'
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import * as tokenn from '../tokens/token';
import * as acesstokenn from '../tokens/token';
import Button from '@mui/material/Button';


import { BarLoader, DoubleBubble, SlidingPebbles, Spinner, DoubleOrbit, HalfMalf, TripleMaze } from 'react-spinner'


const ValidationTextField = styled(TextField)({'& input:valid + fieldset': {borderColor: 'green',borderWidth: 2,},'& input:valid:focus + fieldset': {borderLeftWidth: 6,padding: '4px !important',},});

const currencies = [{value: 'FÍSICA',label: 'FÍSICA',},{value: 'MORAL',label: 'MORAL',},];
const op1 = [{value: 'CLIENTE',label: 'CLIENTE',},{value: 'PROVEEDOR',label: 'PROVEEDOR',},{value: 'AMBOS',label: 'AMBOS',},];
const op2 = [{value: 'PROPIO',label: 'PROPIO',},{value: 'UNILEVER',label: 'UNILEVER',},{value: 'EXTERNO',label: 'EXTERNO',},];


const op3 = [{value: 'G01',label: 'Adquisición de mercancias',},{value: 'G02',label: 'Devoluciones, descuentos o bonificaciones',},
{value: 'G03',label: 'Gastos en general',},
{value: 'I01',label: 'Construcciones',},{value: 'I02',label: 'Mobilario y equipo de oficina por inversiones',},
{value: 'I03',label: 'Equipo de transporte',},{value: 'I04',label: 'Equipo de computo y accesorios',},
{value: 'I05',label: 'Dados, troqueles, moldes, matrices y herramental',},{value: 'I06',label: 'Comunicaciones telefónicas',},
{value: 'I07',label: 'Comunicaciones satelitales',},{value: 'I08',label: 'Otra maquinaria y equipo',},
{value: 'D01',label: 'Honorarios médicos, dentales y gastos hospitalarios',},{value: 'D02',label: 'Gastos médicos por incapacidad o discapacidad',},
{value: 'D03',label: 'Gastos funerales',},{value: 'D04',label: 'Donativos',},{value: 'D05',label: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)',},
{value: 'D06',label: 'Aportaciones voluntarias al SAR',},{value: 'D07',label: 'Primas por seguros de gastos médicos',},
{value: 'D08',label: 'Gastos de transportación escolar obligatoria',},{value: 'D089',label: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones',},
{value: 'D10',label: 'Pagos por servicios educativos (colegiaturas)',},{value: 'P01',label: 'Por definir',},];


const op4 = [{value: '01',label: 'Efectivo',},{value: '02',label: 'Cheque',},{value: '03',label: 'Transferencia Electrénica de fondos',},
{value: '04',label: 'Tarjeta de crédito',},{value: '28',label: 'Tarjeta de débito',},{value: '29',label: 'Tarjeta de servicio',},
{value: '99',label: 'Otros',},{value: 'NA',label: 'No Aplica',},];
const op5 = [{value: '01',label: 'PUE',},{value: '02',label: 'PPD',},];

const op7 = [{value: 'FISCAL',label: 'FISCAL',},{value: 'FISCAL',label: 'FISCAL',},];
const op8 = [{value: 'MATRIZ',label: 'MATRIZ',},{value: 'MATRIZ',label: 'MATRIZ',},];


const op6 = [{value: 'AGUASCALIENTES',label: 'AGUASCALIENTES',},{value: 'BAJA CALIFORNIA',label: 'BAJA CALIFORNIA',},
{value: 'BAJA CALIFORNIA SUR',label: 'BAJA CALIFORNIA SUR',},{value: 'CAMPECHE',label: 'CAMPECHE',},
{value: 'CHIAPAS',label: 'CHIAPAS',},{value: 'CHIHUAHUA',label: 'CHIHUAHUA',},
{value: 'CIUDAD DE MEXICO',label: 'CIUDAD DE MEXICO',},{value: 'COAHUILA',label: 'COAHUILA',},
{value: 'DURANGO',label: 'DURANGO',},{value: 'ESTADO DE MEXICO',label: 'ESTADO DE MEXICO',},
{value: 'GUANAJUATO',label: 'GUANAJUATO',},{value: 'GUERRERO',label: 'GUERRERO',},
{value: 'HIDALGO',label: 'HIDALGO',},{value: 'JALISCO',label: 'JALISCO',},
{value: 'MICHOACAN',label: 'MICHOACAN',},{value: 'MORELOS',label: 'MORELOS',},
{value: 'NAYARIT',label: 'NAYARIT',},{value: 'NUEVO LEON',label: 'NUEVO LEON',},
{value: 'OAXACA',label: 'OAXACA',},{value: 'PUEBLA',label: 'PUEBLA',},
{value: 'QUERETARO',label: 'QUERETARO',},{value: 'SAN LUIS POTOSI',label: 'SAN LUIS POTOSI',},
{value: 'SINALOA',label: 'SINALOA',},{value: 'SONORA',label: 'SONORA',},
{value: 'TABASCO',label: 'TABASCO',},{value: 'TAMAULIPAS',label: 'TAMAULIPAS',},
{value: 'TLAXCALA',label: 'TLAXCALA',},{value: 'VERACRUZ',label: 'VERACRUZ',},
{value: 'YUCATAN',label: 'YUCATAN',},{value: 'ZACATECAS',label: 'ZACATECAS',},
{value: 'OTRO',label: 'OTRO',},

];


                             
                                   

const SetSocioNegocio  = () => {

    const [values, setValues] = useState({code: '', business_partner: '', rfc:'',tax_regime:'', name: '',first_last_name: '',second_last_name: '',
                                        balance: '', credit_days: '', business_partner_type: '',capital_regime:'', partner_type: '', person_type: '',
                                        cfdi_use: '', email: '', phone: '', metodo_pago: '', paymethod: '', account_number: '',
                                        allow_invoicing:Boolean, street: '', outdoor_number: '', inside_number: '', neighborhood:'',
                                        zip_code: '', city: '', state: '', town: '', country: '', reference: '', address_type: '',
                                        subsidiary: ''});
    const [sent, setSent] = useState(false);
    const handleChange = e => {
        const {name, value} = (e.target)
        setValues({...values, [name]: value})
    }

    const [currency, setCurrency] = React.useState('FÍSICA');
    const [opp1, setOP1] = React.useState('CLIENTE');
    const [opp2, setOP2] = React.useState('PROPIO');
    const [opp3, setOP3] = React.useState('G01'); 
    const [opp4, setOP4] = React.useState('01');
    const [opp5, setOP5] = React.useState('01');
    const [opp6, setOP6] = React.useState('AGUASCALIENTES');
    const [opp7, setOP7] = React.useState('FISCAL');
    const [opp8, setOP8] = React.useState('MATRIZ');



    const handleChangee = (event) => {
        setCurrency(event.target.value);
        setOP1(event.target.value);
        setOP2(event.target.value);
        setOP3(event.target.value);
        setOP4(event.target.value);
        setOP5(event.target.value);
        setOP6(event.target.value);
        setOP7(event.target.value);
        setOP8(event.target.value);


      };


    function ShowSelected01()
        {
        var businessPartnerType = document.getElementById("businessPartnerType").value
        return businessPartnerType;
        }
        function ShowSelected02()
        {
        var partnerType = document.getElementById("partnerType").value
        return partnerType;
        }
        
        function ShowSelected03()
        {      
        var personType = document.getElementById("personType").value
        return personType;
        }
        function ShowSelected04()
        {
        var CFDIUse = document.getElementById("CFDIUse").value
        return CFDIUse;
        }
        function ShowSelected05()
        {
        var payMethod = document.getElementById("payMethod").value
        return payMethod;
        }
        function ShowSelected06()
        {        
        var metodoPago = document.getElementById("metodoPago").value
        return metodoPago;
        }
        function ShowSelected07(){}
        
        
        function ShowSelected08()
        {
        var state = document.getElementById("state").value
        return state;
        }
        function ShowSelected09()
        {
        var addressType = document.getElementById("addressType").value
        return addressType;
        }
        function ShowSelected10()
        {
        var subsidiary = document.getElementById("subsidiary").value
        return subsidiary;
        }

    async function handleSubmit(event) {

        event.preventDefault();
        var businessPartnerType = ShowSelected01();
        var partnerType = ShowSelected02();
        var personType = ShowSelected03();
        var cfdiUse = ShowSelected04();
        var payMethod = ShowSelected05();
        var metodoPago = ShowSelected06();
        var state = ShowSelected08();
        var addressType = ShowSelected09();
        var subsidiary = ShowSelected10();

        const acceso= String(tokenn.tokenn);
        const accesotoken = String(acesstokenn.acesstokenn);
        
        const response=await axios.post(
            'https://lhtfgusul2.execute-api.us-east-1.amazonaws.com/dev/businesspartners',{
            headers: { 
                'Authorization':  acceso, 
                'access-token': accesotoken,
                'Content-Type': 'application/json'
                },
            body:{
                  "business_partner": {
                  "code": `${values.code}`,
                  "business_partner": `${values.business_partner}`,
                  "rfc":  `${values.rfc}`,
                  "tax_regime":`${values.tax_regime}`,
                  "name": `${values.name}`,
                  "first_last_name": `${values.first_last_name}`,
                  "second_last_name": `${values.second_last_name}`,
                  "balance": `${values.balance}`,
                  "credit_days": `${values.credit_days}`,
                  "business_partner_type": `${businessPartnerType}`,
                  "partner_type": `${partnerType}`,
                  "person_type": `${personType}`,
                  "cfdi_use": `${cfdiUse}`,
                  "email": `${values.email}`,
                  "capital_regime":`${values.capital_regime}`,
                  "phone": `${values.phone}`,
                  "metodo_pago": `${metodoPago}`,
                  "paymethod": `${payMethod}`,
                  "account_number": `${values.account_number}`
                },
                  "address": {
                  "street": `${values.street}`,
                  "outdoor_number": `${values.outdoor_number}`,
                  "inside_number": `${values.inside_number}`,
                  "neighborhood": `${values.neighborhood}`,
                  "zip_code": `${values.zip_code}`,
                  "city": `${values.city}`,
                  "state": `${state}`,
                  "town": `${values.town}`,
                  "country": `${values.country}`,
                  "reference": `${values.reference}`,
                  "address_type": `${addressType}`,
                  "subsidiary": `${subsidiary}`,
                }
            }
            });
        // console.log(response);
        setSent(true);
        
    }

    if (!sent) {
        return (
            
           
            <div>
                               <h2>Crear Nuevo Socio de Negocios</h2>

            <form onSubmit={handleSubmit}>
                 <Box>
                    
                    <ValidationTextField
                        label="Codigo"
                        required
                        placeholder="Codigo"
                        sx={{ m: 2, width: '16ch' }}
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        name='code'
                        value={values.code}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                          }}
                        
                    />
                    <ValidationTextField
                        label="Socio de Negocios "
                        required
                        placeholder="Socio de Negocios"
                        sx={{ m: 2, width: '40ch' }}
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        name='business_partner'
                        value={values.business_partner}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                          }}
                    />
                
                    <ValidationTextField
                        label="RFC"
                        required
                        placeholder="RFC"
                        sx={{ m: 2, width: '20h' }}
                        variant="outlined"
                        defaultValue="Success"
                        id="getRFC"
                        name='rfc'
                        value={values.rfc}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                          }}
                    />
                    <ValidationTextField
                        label="Regimen Fiscal"
                        required
                        placeholder="Regimen Fiscal"
                        variant="outlined"
                        sx={{ m: 2, width: '28.5ch' }}
                        defaultValue="Success"
                        id="validation-outlined-input"
                        name='tax_regime'
                        value={values.tax_regime}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                          }}
                    />
                    <ValidationTextField
                        label="Regimen Capital"
                        required
                        placeholder="Regimen Capital"
                        variant="outlined"
                        sx={{ m: 2, width: '28.5ch' }}
                        defaultValue="Success"
                        id="validation-outlined-input"
                        name='capital_regime'
                        value={values.capital_regime}
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true,
                          }}
                    />
                    <TextField
                    id="partnerType"
                    select
                    label=" Clasificación del cliente"
                    value={currency}
                    sx={{ m: 1.5, width: '12.5ch' }}
                    onChange={handleChangee}
                    size="small"
                    name='partnerType'
                    InputLabelProps={{
                        shrink: true,
                      }}
                    SelectProps={{
                        native: true,
                    }}
                    >
                    {currencies.map((option) => (
                        <option key={option.value} value={option.value}>
                        {option.label}
                        </option>
                    ))}
                    </TextField>
                    </Box>

                    <Box>

                    <ValidationTextField
                        label="Nombre del representante legal "
                        required
                        variant="outlined"
                        placeholder="Nombre del representante legal"                    
                        sx={{ m: 2, width: '54ch' }}
                        defaultValue="Success"
                        id="validation-outlined-input"
                        name='name'
                        value={values.name}
                        InputLabelProps={{
                            shrink: true,
                          }}
                        onChange={handleChange}
                    />

                    <ValidationTextField
                        label="Apellido Paterno del representante legal "
                        required
                        placeholder="Apellido Paterno del representante legal"                    
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '54ch' }}
                        name='first_last_name'
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.first_last_name}
                        onChange={handleChange}
                    />
                    <ValidationTextField
                        label="Apellido Materno del representante legal"
                        required
                        placeholder="Apellido Materno del representante legal"                    
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '54ch' }}
                        name='second_last_name'
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.second_last_name}
                        onChange={handleChange}
                    />
                    </Box>

                    <Box>
                    <ValidationTextField
                        label="Correo Electronico"
                        required
                        placeholder="Correo Electronico"                    
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '54ch' }}
                        name='email'
                        type="email"
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.email}
                        onChange={handleChange}
                    />
                    <ValidationTextField
                        label="Telefono"
                        required
                        placeholder="Numero telefonico"                    
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '21ch' }}
                        name='phone'
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.phone}
                        onChange={handleChange}
                    />
                    <ValidationTextField
                        label="Linea de credito"
                        required
                        placeholder="Linea de credito"                    
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '20ch' }}
                        name='balance'
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.balance}
                        onChange={handleChange}
                    />
                    <ValidationTextField
                        label="Dias de credito"
                        required
                        placeholder="Dias de credito"                  
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '20ch' }}
                        name='credit_days'
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.credit_days}
                        onChange={handleChange}
                    />
                    <TextField
                    id="businessPartnerType"
                    select
                    label="Tipo de socio de negocio"
                    value={opp1}
                    sx={{ m: 1.5, width: '19.5ch' }}
                    onChange={handleChangee}
                    size="small"
                    name='businessPartnerType'
                    InputLabelProps={{
                        shrink: true,
                      }}
                    SelectProps={{
                        native: true,
                    }}
                    >
                    {op1.map((option) => (
                        <option key={option.value} value={option.value}>
                        {option.label}
                        </option>
                    ))}
                    </TextField>
                    <TextField
                    id="partnerType"
                    select
                    label="Tipo de Persona"
                    value={opp2}
                    sx={{ m: 1.5, width: '19ch' }}
                    onChange={handleChangee}
                    size="small"
                    name='partnerType'
                    InputLabelProps={{
                        shrink: true,
                      }}
                    SelectProps={{
                        native: true,
                    }}
                    >
                    {op2.map((option) => (
                        <option key={option.value} value={option.value}>
                        {option.label}
                        </option>
                    ))}
                    </TextField>

                    </Box>

                    <Box>
                    <TextField
                    id="CFDIUse"
                    select
                    label="Uso de CFDI"
                    value={opp3}
                    sx={{ m: 1.5, width: '80ch' }}
                    onChange={handleChangee}
                    size="small"
                    name='CFDIUse'
                    InputLabelProps={{
                        shrink: true,
                      }}
                    SelectProps={{
                        native: true,
                    }}
                    >
                    {op3.map((option) => (
                        <option key={option.value} value={option.value}>
                        {option.label}
                        </option>
                    ))}
                    </TextField>
                    <TextField
                    id="payMethod"
                    select
                    label="Metodo de pago"
                    value={opp4}
                    sx={{ m: 1.5, width: '40ch' }}
                    onChange={handleChangee}
                    size="small"
                    name='payMethod'
                    InputLabelProps={{
                        shrink: true,
                      }}
                    SelectProps={{
                        native: true,
                    }}
                    >
                    {op4.map((option) => (
                        <option key={option.value} value={option.value}>
                        {option.label}
                        </option>
                    ))}
                    </TextField>
                    <TextField
                    id="metodoPago"
                    select
                    label="Pago"
                    value={opp5}
                    sx={{ m: 1.5, width: '10ch' }}
                    onChange={handleChangee}
                    size="small"
                    name='metodoPago'
                    InputLabelProps={{
                        shrink: true,
                      }}
                    SelectProps={{
                        native: true,
                    }}
                    >
                    {op5.map((option) => (
                        <option key={option.value} value={option.value}>
                        {option.label}
                        </option>
                    ))}
                    </TextField>

                    <ValidationTextField
                        label="Numero de cuenta"
                        required
                        placeholder="Numero de cuenta"                    
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '31ch' }}
                        name='account_number'
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.account_number}
                        onChange={handleChange}
                    />
                    </Box>

                    <Box>

                    <ValidationTextField
                        label="Calle"
                        required
                        placeholder="Calle"                    
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '42ch' }}
                        name='street'
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.street}
                        onChange={handleChange}
                    />

                    <ValidationTextField
                        label="Numero extrerior"
                        required
                        placeholder="Numero extrerior"                    
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '15ch' }}
                        name='outdoor_number'
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.outdoor_number}
                        onChange={handleChange}
                    />
                    <ValidationTextField
                        label="Numero interior"
                        required
                        placeholder="Numero interior"                    
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '15ch' }}
                        name='inside_number'
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.inside_number}
                        onChange={handleChange}
                    />

                    <ValidationTextField
                        label="Colonia"
                        required
                        placeholder="Colonia"                    
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '40ch' }}
                        name='neighborhood'
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.neighborhood}
                        onChange={handleChange}
                    />
                     <ValidationTextField
                        label="Codigo Postal"
                        required
                        placeholder="Codigo Postal"                    
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '15ch' }}
                        name='zip_code'
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.zip_code}
                        onChange={handleChange}
                    />
                     <ValidationTextField
                        label="Municipio"
                        required
                        placeholder="Municipio"                    
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '25ch' }}
                        name='city'
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.city}
                        onChange={handleChange}
                    />
                    </Box>

                    <Box>

                    <TextField
                    id="state"
                    select
                    label="Estado"
                    value={opp6}
                    sx={{ m: 1.5, width: '30ch' }}
                    onChange={handleChangee}
                    size="small"
                    name='state'
                    InputLabelProps={{
                        shrink: true,
                      }}
                    SelectProps={{
                        native: true,
                    }}
                    >
                    {op6.map((option) => (
                        <option key={option.value} value={option.value}>
                        {option.label}
                        </option>
                    ))}
                    </TextField>
                    <ValidationTextField
                        label="Localida"
                        required
                        placeholder="Localidad"                    
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '30ch' }}
                        name='town'
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.town}
                        onChange={handleChange}
                    />
                     <ValidationTextField
                        label="País"
                        required
                        placeholder="País"                    
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '20ch' }}
                        name='country'
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.country}
                        onChange={handleChange}
                    />
                     <ValidationTextField
                        label="Referencia"
                        required
                        placeholder="Referencia"                    
                        variant="outlined"
                        defaultValue="Success"
                        id="validation-outlined-input"
                        sx={{ m: 2, width: '79.5ch' }}
                        name='reference'
                        InputLabelProps={{
                            shrink: true,
                          }}
                        value={values.reference}
                        onChange={handleChange}
                    />
                    </Box>

                    <Box>
                     <TextField
                    id="addressType"
                    select
                    label="Tipo de dirección"
                    value={opp7}
                    sx={{ m: 1.5, width: '15ch' }}
                    onChange={handleChangee}
                    size="small"
                    name='addressType'
                    InputLabelProps={{
                        shrink: true,
                      }}
                    SelectProps={{
                        native: true,
                    }}
                    >
                    {op7.map((option) => (
                        <option key={option.value} value={option.value}>
                        {option.label}
                        </option>
                    ))}
                    </TextField>
                    <TextField
                    id="subsidiary"
                    select
                    label="Sucursal"
                    value={opp8}
                    sx={{ m: 1.5, width: '15ch' }}
                    onChange={handleChangee}
                    size="small"
                    name='subsidiary'
                    InputLabelProps={{
                        shrink: true,
                      }}
                    SelectProps={{
                        native: true,
                    }}
                    >
                    {op8.map((option) => (
                        <option key={option.value} value={option.value}>
                        {option.label}
                        </option>
                    ))}
                    </TextField>

                 </Box>                

                 <Button variant="contained" type="submit">Enviar</Button>

                    
                </form>



            </div>
        );
    } else {
        return (
            <div style={{paddingLeft: '1vw',paddingTop: '1vh'}}>
              <p>Socio de negocios registro! </p>
              <p></p>
            </div>            
        )
    }
}        

export default SetSocioNegocio;
